<template>
    <div class="card card-custom gutter-b">
        <!--begin::Body-->
        <div class="card-body p-0">
            <div class="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
                <span class="symbol symbol-circle symbol-50 symbol-light-danger mr-2">
                    <span class="symbol-label" style="float:left;">
                        <span class="svg-icon svg-icon-xl svg-icon-danger">
                          <inline-svg src="media/svg/icons/Layout/Layout-4-blocks.svg"/>
                        </span>
                    </span>
                    <span class="mt-2 ml-5" style="float:left;">
                        <Dropdown
                            @search="siteSelectEvent"
                        >
                            <i class="ki ki-bold-more-hor text-white"></i>
                        </Dropdown>
                    </span>
                </span>
                <div class="d-flex flex-column text-right">
                    <span class="text-dark-75 font-weight-bolder font-size-h3">订单趋势</span>
                    <span class="text-muted font-weight-bold mt-2">{{site_name}}</span>
                </div>
            </div>
            <!--begin::Chart-->
            <apexchart
                    class="card-rounded-bottom"
                    :options="chartOptions"
                    :series="series"
                    type="area"
            ></apexchart>
            <!--end::Chart-->
        </div>
        <!--end::Body-->
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import Dropdown from "@/view/content/dropdown/SiteDropdown.vue";
    import { getOrderTrend } from '@/api/index';
    export default {
        name: "order_trend",
        components: {
            Dropdown
        },
        created: function() {
            this.loadOrderTrendData({});
        },
        data() {
            return {
                chartOptions: {},
                series: [
                    {
                        name: "订单数",
                        data: []
                    }
                ],
                categories: [],
                site_name: '全部站点',
            };
        },
        computed: {
            ...mapGetters(["layoutConfig"])
        },
        mounted() {
        },
        watch: {
            series: {
                handler (val, oldval) {

                    this.chartOptions = {
                        chart: {
                            type: "area",
                            height: 150,
                            toolbar: {
                                show: false
                            },
                            zoom: {
                                enabled: false
                            },
                            sparkline: {
                                enabled: true
                            }
                        },
                        plotOptions: {},
                        legend: {
                            show: false
                        },
                        dataLabels: {
                            enabled: false
                        },
                        fill: {
                            type: "solid",
                            opacity: 1
                        },
                        stroke: {
                            curve: "smooth",
                            show: true,
                            width: 3,
                            colors: [this.layoutConfig("colors.theme.base.danger")]
                        },
                        xaxis: {
                            categories: this.categories,
                            axisBorder: {
                                show: false
                            },
                            axisTicks: {
                                show: false
                            },
                            labels: {
                                show: false,
                                style: {
                                    colors: this.layoutConfig("colors.gray.gray-500"),
                                    fontSize: "12px",
                                    fontFamily: this.layoutConfig("font-family")
                                }
                            },
                            crosshairs: {
                                show: false,
                                position: "front",
                                stroke: {
                                    color: this.layoutConfig("colors.gray.gray-300"),
                                    width: 1,
                                    dashArray: 3
                                }
                            },
                            tooltip: {
                                enabled: false
                            }
                        },
                        yaxis: {
                            min: 0,
                            max: 50,
                            show: false,
                            labels: {
                                show: false,
                                style: {
                                    colors: this.layoutConfig("colors.gray.gray-500"),
                                    fontSize: "12px",
                                    fontFamily: this.layoutConfig("font-family")
                                }
                            }
                        },
                        states: {
                            normal: {
                                filter: {
                                    type: "none",
                                    value: 0
                                }
                            },
                            hover: {
                                filter: {
                                    type: "none",
                                    value: 0
                                }
                            },
                            active: {
                                allowMultipleDataPointsSelection: false,
                                filter: {
                                    type: "none",
                                    value: 0
                                }
                            }
                        },
                        tooltip: {
                            style: {
                                fontSize: "12px",
                                fontFamily: this.layoutConfig("font-family")
                            },
                            y: {
                                formatter: function(val) {
                                    return val;
                                }
                            }
                        },
                        colors: [this.layoutConfig("colors.theme.light.danger")],
                        markers: {
                            colors: [this.layoutConfig("colors.theme.light.danger")],
                            strokeColor: [this.layoutConfig("colors.theme.base.danger")],
                            strokeWidth: 3
                        },
                        grid: {
                            show: false,
                            padding: {
                                left: 0,
                                right: 0
                            }
                        }
                    };
                },
                deep: true
            }
        },
        methods: {
            siteSelectEvent(result) {
                var data = {
                    'site_id': result.site_id,
                };
                this.site_name = result.site_name;
                this.loadOrderTrendData(data);
            },
            loadOrderTrendData(data) {
                var _this = this;
                getOrderTrend(data).then(function(res){
                    if(res && res.response) {
                        _this.series[0].data = res.response.series;
                        _this.categories = res.response.dates;
                    }
                });
            }
        }
    };
</script>
