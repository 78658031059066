import request from './request';

export function getMenu(){
  return request({
    url: 'index/getMenu',
    method: 'post',
    data: null
  });
}

export function getOrderChats(params){
  return request({
    url: 'index/getOrderChats',
    method: 'post',
    data: params
  });
}


export function getSiteList(params){
  return request({
    url: 'index/getSiteList',
    method: 'post',
    data: params
  });
}

export function getOrderTrend(params){
  return request({
    url: 'index/getOrderTrend',
    method: 'post',
    data: params
  });
}

export function getNotify(params){
  return request({
    url: 'index/getNotify',
    method: 'post',
    data: params
  });
}

export function getUserInfo(params){
  return request({
    url: 'index/getUserInfo',
    method: 'post',
    data: params
  });
}

