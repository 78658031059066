<template>
    <b-dropdown
            size="sm"
            variant="link"
            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
            no-caret
            right
            no-flip
            ref="dropdown"
    >
        <template v-slot:button-content>
            <slot></slot>
        </template>
        <!--begin::Navigation-->
        <div class="navi navi-hover min-w-md-350px">
            <b-dropdown-text tag="div" class="navi-header font-weight-bold">
                <span class="font-size-lg">
                  选择日期区间
                </span>
                <button class="btn btn-light-primary font-weight-bolder" style="float:right;" @click="search()">查询</button>
            </b-dropdown-text>
            <b-dropdown-text
                    tag="div"
                    class="navi-separator mb-3 opacity-70"
            ></b-dropdown-text>

            <b-dropdown-text tag="div" class="navi-item">
                <template>
                    <div class="d-flex align-items-center">
                        <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">开始时间:</label>
                        <input
                                type="text"
                                class="form-control"
                                placeholder="请选择日期"
                                v-model="begin_time"
                                readonly
                                @focus="begin_pick_show = true; end_pick_show = false"
                        >
                    </div>
                    <template v-if="begin_pick_show">
                        <v-date-picker
                                v-model="begin_time"
                                no-title
                                color="#1BC5BD"
                        ></v-date-picker>
                    </template>
                </template>
            </b-dropdown-text>

            <b-dropdown-text tag="div" class="navi-item mb-3">
                <template>
                    <div class="d-flex align-items-center">
                        <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">结束时间:</label>
                        <input
                                type="text"
                                class="form-control"
                                placeholder="请选择日期"
                                v-model="end_time"
                                readonly
                                @focus="end_pick_show = true; begin_pick_show = false;"
                        >
                    </div>
                    <template v-if="end_pick_show">
                        <v-date-picker
                                v-model="end_time"
                                no-title
                                color="#1BC5BD"
                        ></v-date-picker>
                    </template>
                </template>
            </b-dropdown-text>

            <b-dropdown-text
                    tag="div"
                    class="navi-separator mb-3 opacity-70"
            ></b-dropdown-text>
            <b-dropdown-text tag="div" class="navi-item">
                <label class="navi-text mb-2 mr-5">
                    <a class="btn btn-primary" @click="dateItemSelect()">
                        所有时间
                    </a>
                </label>
                <label class="navi-text mb-2 mr-5">
                    <a class="btn btn-primary" @click="dateItemSelect(1)">
                        一周内
                    </a>
                </label>
                <label class="navi-text mb-2 mr-5">
                    <a class="btn btn-primary" @click="dateItemSelect(2)">
                        半个月内
                    </a>
                </label>
                <label class="navi-text mb-2 mr-5">
                    <a class="btn btn-primary" @click="dateItemSelect(3)">
                        一个月内
                    </a>
                </label>
                <label class="navi-text mb-2 mr-5">
                    <a class="btn btn-primary" @click="dateItemSelect(4)">
                        三个月内
                    </a>
                </label>
                <label class="navi-text mb-2 mr-5">
                    <a class="btn btn-primary" @click="dateItemSelect(5)">
                        半年内
                    </a>
                </label>
                <label class="navi-text mb-2 mr-5">
                    <a class="btn btn-primary" @click="dateItemSelect(6)">
                        一年内
                    </a>
                </label>
                <label class="navi-text mb-2 mr-5">
                    <a class="btn btn-primary" @click="dateItemSelect(7)">
                        两年内
                    </a>
                </label>
            </b-dropdown-text>
        </div>
        <!--end::Navigation-->
    </b-dropdown>
</template>

<style lang="scss">
    .custom-v-dropdown {
        &.dropdown-toggle {
            padding: 0;
            &:hover {
                text-decoration: none;
            }

            &.dropdown-toggle-no-caret {
                &:after {
                    content: none;
                }
            }
        }

        &.dropdown-menu {
            margin: 0;
            padding: 0;
            outline: none;
            .b-dropdown-text {
                padding: 0;
            }
        }
    }
</style>

<script>

    import KTUtil from "@/assets/js/components/util";

    export default {
        name: "date_dropdown",
        components: {

        },
        mounted() {

        },
        data() {
            return {
                begin_time: '',
                begin_pick_show: false,
                end_time: '',
                end_pick_show: false
            }
        },
        watch:{


        },
        methods: {
            dateItemSelect(type) {
                let now = new Date();
                let before = new Date();
                switch(type) {
                    case 1:
                        before.setDate(before.getDate() - 7);
                        break;
                    case 2:
                        before.setDate(before.getDate() - 15);
                        break;
                    case 3:
                        before.setMonth(before.getMonth() - 1);
                        break;
                    case 4:
                        before.setMonth(before.getMonth() - 3);
                        break;
                    case 5:
                        before.setMonth(before.getMonth() - 6);
                        break;
                    case 6:
                        before.setFullYear(before.getFullYear() - 1);
                        break;
                    case 7:
                        before.setFullYear(before.getFullYear() - 2);
                        break;
                    default:
                        break;
                }
                if(type) {
                    this.begin_time = KTUtil.timestamp2date(before / 1000).substring(0, 10);
                    this.end_time = KTUtil.timestamp2date(now / 1000).substring(0, 10);
                } else {
                    this.begin_time = '';
                    this.end_time = '';
                }
            },
            search(){
                this.$emit('search', {
                    begin: this.begin_time,
                    end: this.end_time
                });
                this.$refs.dropdown.hide(true);
            },
        }
    };
</script>
