<template>
    <div class="card card-custom card-stretch gutter-b">
        <!--begin::Header-->
        <div class="card-header border-0">
            <h3 class="card-title font-weight-bolder text-dark">处理事项</h3>
            <div class="card-toolbar">

            </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-0">
            <template v-for="(item, i) in list">
                <!--begin::Item-->
                <div class="mb-10" v-bind:key="i">
                    <!--begin::Section-->
                    <div class="d-flex align-items-center">
                        <!--begin::Symbol-->
                        <div class="symbol symbol-45 symbol-light mr-5">
              <span class="symbol-label">
                <inline-svg
                        :src="item.svg"
                        class="h-50 align-self-center"
                ></inline-svg>
              </span>
                        </div>
                        <!--end::Symbol-->
                        <!--begin::Text-->
                        <div class="d-flex flex-column flex-grow-1">
                            <a
                                    :href="item.href"
                                    class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                            >
                                {{ item.title }}
                            </a>
                            <span class="text-muted font-weight-bold">
                {{ item.alt }}
              </span>
                        </div>
                        <!--end::Text-->
                    </div>
                    <!--end::Section-->
                    <!--begin::Desc-->

                    <!--end::Desc-->
                </div>
                <!--end::Item-->
            </template>
        </div>
        <!--end::Body-->
    </div>
</template>


<script>
    import { mapGetters } from "vuex";
    import { getNotify } from '@/api/index';
    export default {
        name: "product_census",
        components: {

        },
        created: function() {
            var _this = this;
            getNotify({}).then(function(res){
                if(res && res.response) {

                    _this.list = [{
                        title: "待处理的采购单",
                        alt: res.response.purchase_count + " 项",
                        svg: "media/svg/misc/006-plurk.svg",
                        href: '/purchasement'
                    },
                    {
                        title: "待处理的盘点",
                        alt: res.response.checkwarehouse_count + " 项",
                        svg: "media/svg/misc/015-telegram.svg",
                        href: '/checkwarehouse'
                    },
                    {
                        title: "待处理的调拨",
                        alt: res.response.warehouseallot_count + " 项",
                        svg: "media/svg/misc/003-puzzle.svg",
                        href: '/warehouseallot'
                    },
                    {
                        title: "待处理的订单",
                        alt: res.response.order_count + " 项",
                        svg: "media/svg/misc/014-kickstarter.svg",
                        href: '/order'
                    },
                    {
                        title: "待配置的第三方插件",
                        alt: res.response.apiconfig_count +" 项",
                        svg: "media/svg/misc/005-bebo.svg",
                        href: '/interconfig'
                    }];

                }
            });
        },
        data() {
            return {
                list: [

                ]
            };
        },
        computed: {
            ...mapGetters(["layoutConfig"])
        },
        mounted() {
        },
        watch: {

        },
        methods: {


        }
    };
</script>
