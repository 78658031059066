<template>
    <b-dropdown
            size="lg"
            variant="link"
            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
            no-caret
            right
            no-flip
            ref="dropdown"
    >
        <template v-slot:button-content>
            <i class="ki ki-bold-more-hor"></i>
        </template>
        <!--begin::Navigation-->
        <div class="navi navi-hover min-w-md-350px">
            <b-dropdown-text tag="div" class="navi-header font-weight-bold">
                <span class="font-size-lg">
                  选择站点
                </span>
            </b-dropdown-text>

            <b-dropdown-text tag="div" class="navi-item">
                <label class="navi-text mb-2 mr-5">
                    <a class="btn btn-primary" @click="siteItemSelect('', '全部站点')">
                       全部站点
                    </a>
                </label>
                <template v-for="(site, idx) in site_list">
                    <label :key="idx" class="navi-text mb-2 mr-5">
                        <a class="btn btn-primary" @click="siteItemSelect(site.id, site.site_name)">
                            {{site.site_name}}
                        </a>
                    </label>
                </template>
            </b-dropdown-text>
        </div>
        <!--end::Navigation-->
    </b-dropdown>
</template>

<style lang="scss">
    .custom-v-dropdown {
        &.dropdown-toggle {
            padding: 0;
            &:hover {
                text-decoration: none;
            }

            &.dropdown-toggle-no-caret {
                &:after {
                    content: none;
                }
            }
        }

        &.dropdown-menu {
            margin: 0;
            padding: 0;
            outline: none;
            .b-dropdown-text {
                padding: 0;
            }
        }
    }
</style>

<script>

    import { getSiteList } from '@/api/index';

    export default {
        name: "site_dropdown",
        components: {},
        mounted() {},
        created: function() {
            var _this = this;
            getSiteList().then(function(res) {
                if(res && res.response) {
                    _this.site_list = res.response;
                }
            });
        },
        data() {
            return {
                site_list: []
            };
        },
        methods: {
            siteItemSelect(site_id, site_name) {
                this.$emit('search', {
                    site_id: site_id,
                    site_name: site_name
                });
                this.$refs.dropdown.hide(true);
            },
        }
    };
</script>
