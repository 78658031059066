import axios from "axios";
const global = require('../../config/global.env');
import KTUtil from "@/assets/js/components/util";

//创建请求实体
let instance = axios.create({
  timeout: 500000,
  headers: {
    'content-type': 'application/json'
  }
});
instance.defaults.baseURL = "/api/";
instance.defaults.withCredentials = true;

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么

  //添加登录验证信息
  config.headers['Authorization-Token'] = localStorage.getItem(global.APP_NAME + '_Authorization_Token');
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {

  // 对响应数据做点什么，登录验证,和登录刷新
  if (response.data.code == 200) {
    return response.data;
  } else if(response.data.code == 500){
    KTUtil.alert('错误', response.data.message, 'error');
  } else if(response.data.code == 504){

    //删除登录缓存
    localStorage.removeItem(global.APP_NAME + '_Authorization_Token');

    //跳转登录页
    KTUtil.alert('', '登录超时，请重新登录', 'error', function(){
      window.location.href = '/login';
    });

  } else if(response.data.code == 505){
    //删除登录缓存
    localStorage.removeItem(global.APP_NAME + '_Authorization_Token');
    //跳转登录页
    KTUtil.alert('', '验证登录信息异常，请重新登录', 'error', function(){
      window.location.href = '/login';
    });
  } else if(response.data.code == 401){
    KTUtil.alert('', '无访问权限', 'error', function(){
      window.location.href = '/401';
    });
  } else if(response.data.code == 501){
    KTUtil.alert('错误', response.data.message, 'error');
  }
  return null;
}, function (error) {

  // 对响应错误做点什么
  KTUtil.alert('错误', error, 'error');
  return Promise.reject(error);
});
export default instance;
