<template>
    <div
            class="card card-custom bg-radial-gradient-primary gutter-b card-stretch"
    >
        <!--begin::Header-->
        <div class="card-header border-0 py-5">
            <h3 class="card-title font-weight-bolder text-white">订单分布情况</h3>

            <div class="card-toolbar">
                <Dropdown
                        @search="dateSelectEvent">
                    <i class="ki ki-bold-more-hor text-white"></i>
                </Dropdown>
            </div>

        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body d-flex flex-column p-0">
            <!--begin::Chart-->
            <apexchart
                    class="card-rounded-bottom"
                    :options="chartOptions"
                    :series="series"
                    type="bar"
            ></apexchart>
            <!--end::Chart-->
            <!--begin::Stats-->
            <div class="card-spacer bg-white card-rounded flex-grow-1">
                <!--begin::Row-->
                <div class="row m-0">
                    <div class="col px-8 py-6 mr-8">
                        <div class="font-size-sm text-muted font-weight-bold">
                            已付款订单数
                        </div>
                        <div class="font-size-h4 font-weight-bolder">{{count.payed}}</div>
                    </div>
                    <div class="col px-8 py-6">
                        <div class="font-size-sm text-muted font-weight-bold">
                            已处理订单数
                        </div>
                        <div class="font-size-h4 font-weight-bolder">{{count.dealed}}</div>
                    </div>
                </div>
                <!--end::Row-->
                <!--begin::Row-->
                <div class="row m-0">
                    <div class="col px-8 py-6 mr-8">
                        <div class="font-size-sm text-muted font-weight-bold">
                            已完成订单数
                        </div>
                        <div class="font-size-h4 font-weight-bolder">{{count.complated}}</div>
                    </div>
                    <div class="col px-8 py-6">
                        <div class="font-size-sm text-muted font-weight-bold">
                            退款订单数
                        </div>
                        <div class="font-size-h4 font-weight-bolder">{{count.refunded}}</div>
                    </div>
                </div>
                <!--end::Row-->
            </div>
            <!--end::Stats-->
        </div>
        <!--end::Body-->
    </div>
</template>

<script>
    import Dropdown from "@/view/content/dropdown/DateDropdown.vue";
    import { mapGetters } from "vuex";
    import { getOrderChats } from '@/api/index';

    export default {
        name: "order_chats",
        components: {
            Dropdown
        },
        created: function() {
            this.loadOrderChatsData({});
        },
        data() {
            return {
                chartOptions: {

                },
                series: [

                ],
                categories: [],
                list: [],
                count: {
                    payed: 0,
                    dealed: 0,
                    sended: 0,
                    complated: 0,
                    refunded: 0
                },
            };
        },
        computed: {
            ...mapGetters(["layoutConfig"])
        },
        watch: {
            list: {
                handler (val, oldval) {

                    var _this = this;
                    var serie = {
                        name: '订单数',
                        data: []
                    };
                    var categories = [];
                    _this.list.forEach(function(value) {
                        serie.data.push(value.orders);
                        categories.push(value.site_name);
                    });
                    _this.series = [serie];
                    _this.categories = categories;

                    //初始化表格
                    this.chartOptions = {
                        chart: {
                            type: "bar",
                            height: 200,
                            toolbar: {
                                show: false
                            },
                            sparkline: {
                                enabled: true
                            }
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: ["10px"],
                                endingShape: "rounded"
                            }
                        },
                        legend: {
                            show: false
                        },
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            show: true,
                            width: 2,
                            colors: ["transparent"]
                        },
                        xaxis: {
                            categories: this.categories,
                            axisBorder: {
                                show: false
                            },
                            axisTicks: {
                                show: false
                            },
                            labels: {
                                show: false,
                                style: {
                                    colors: this.layoutConfig("colors.gray.gray-500"),
                                    fontSize: "12px",
                                    fontFamily: this.layoutConfig("font-family")
                                }
                            }
                        },
                        yaxis: {
                            min: 0,
                            max: 100,
                            axisBorder: {
                                show: false
                            },
                            axisTicks: {
                                show: false
                            },
                            labels: {
                                show: false,
                                style: {
                                    colors: this.layoutConfig("colors.gray.gray-500"),
                                    fontSize: "12px",
                                    fontFamily: this.layoutConfig("font-family")
                                }
                            }
                        },
                        fill: {
                            opacity: 1
                        },
                        states: {
                            normal: {
                                filter: {
                                    type: "none",
                                    value: 0
                                }
                            },
                            hover: {
                                filter: {
                                    type: "none",
                                    value: 0
                                }
                            },
                            active: {
                                allowMultipleDataPointsSelection: false,
                                filter: {
                                    type: "none",
                                    value: 0
                                }
                            }
                        },
                        tooltip: {
                            style: {
                                fontSize: "12px",
                                fontFamily: this.layoutConfig("font-family")
                            },
                            y: {
                                formatter: function(val) {
                                    return val;
                                }
                            }
                        },
                        colors: ["#ffffff"],
                        grid: {
                            borderColor: this.layoutConfig("colors.gray.gray-200"),
                            strokeDashArray: 4,
                            yaxis: {
                                lines: {
                                    show: false
                                }
                            }
                        }
                    };

                },
                deep: true
            }
        },
        mounted() {


        },
        methods: {
            dateSelectEvent(result) {
                var data = {
                    'begin_time': result.begin,
                    'end_time': result.end
                };
                this.loadOrderChatsData(data);

            },
            loadOrderChatsData(data) {
                var _this = this;
                getOrderChats(data).then(function(res){
                    if(res && res.response) {
                        _this.list = res.response.list;
                        _this.count = res.response.count;
                    }
                });
            }
        }
    };
</script>
