<template>
    <v-app style="background: transparent;" :class="{}">
    <div class="mt-10">
        <!--begin::Dashboard-->
        <div class="row">

            <div class="col-xxl-8">
                <OrderChats></OrderChats>
            </div>
            <div class="col-xxl-4">
                <ProductCensus></ProductCensus>
            </div>

            <div class="col-xxl-12">
                <OrderTrend></OrderTrend>
            </div>

        </div>
        <!--end::Dashboard-->
    </div>
    </v-app>
</template>
<style lang="css">
    @import "~@/assets/css/common.css";
</style>
<script>
    import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
    import OrderChats from "@/view/pages/index/OrderChats.vue";
    import OrderTrend from "@/view/pages/index/OrderTrend.vue";
    import ProductCensus from "@/view/pages/index/ProductCensus.vue";

    export default {
        name: "index",
        components: {
            OrderChats,
            OrderTrend,
            ProductCensus,
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
        },
        methods: {
            setActiveTab1(event) {
                this.tabIndex = this.setActiveTab(event);
            },
            setActiveTab2(event) {
                this.tabIndex2 = this.setActiveTab(event);
            },
            /**
             * Set current active on click
             * @param event
             */
            setActiveTab(event) {
                // get all tab links
                const tab = event.target.closest('[role="tablist"]');
                const links = tab.querySelectorAll(".nav-link");
                // remove active tab links
                for (let i = 0; i < links.length; i++) {
                    links[i].classList.remove("active");
                }

                // set current active tab
                event.target.classList.add("active");

                // set clicked tab index to bootstrap tab
                return parseInt(event.target.getAttribute("data-tab"));
            }
        }
    };
</script>
